/**
*
* Editorial Header
*
**/

.editorial-header {
  overflow: hidden;

  .breadcrumbs {
    color: $colorBlue;

    a {
      color: $colorBlue;
    }
  }

  .container {

    @include medium {
      position: relative;

      // Pseudo elements to add the background characters
      &::before {
        content: $icon-chevron-large;
        font-family: $fontIcon;
        font-size: 1000px;
        line-height: 0;
        display: block;
        position: absolute;
        top: 0;
        right: 100%;
        color: $colorGrayBackground;
        margin: 168px -35px 0 0;
      }
    }
  }

}

.editorial-header__excerpt {
  @include type--medium(true);
  font-family: $fontHighlight;
}

.editorial-header .tag{
  padding-top: $lineHeight*2;
}

.editorial-header__subtitle {
  @include type--xxlarge;
  padding-top: 0;
  @include medium {
    padding-top: 0;
    padding-bottom: $lineHeight;
  }
  color: $colorBlue;
}

.editorial-header__toc {
  margin-top: $lineHeight;

  ol {
    padding-top: 0;

    @include medium {
      padding-top: 0;
    }
  }
}

.editorial-header__toc-title {
  font-family: $fontHighlight;
  border-bottom: 1px solid $colorGrayKeyline;
  margin-bottom: 13px;
  padding-bottom: 13px !important;
  color: $colorBlue;
}
