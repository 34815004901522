/**
*
* Global
*
**/

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  font-family: $fontDefault;
  font-size: $fontBase;
  line-height: 1.6250em; /* 26px */
  font-weight: 300; // to thicken it a bit, we need to remove font-smoothing
  color: $colorText;

  @include baseline-grid();
}

pre {
  background: $colorGrayBackground;
  padding: 13px;
}

.main-container {
  @include container(true);
}

.container {
  @include container(true);
}

.container-medium {
  @include medium {
    @include container(true);
  }
}

.container-small {
  @include small-only {
    @include container(true);
  }
}

.content {
  @include wide {
    margin-right: 25.9%; // took this from the grid output - using @extend inside media-query is deprecated, need to figure out a better way to do this.

    & pre {
      margin-right: -25.9%;
    }
  }
}
