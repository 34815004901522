/**
*
* Editorial Header
*
**/

.featured-spotlight {
  background: $colorGrayDark;
  color: #ffffff;
  overflow: hidden;
  padding-bottom: $lineHeight * 3 - 1;
  margin-top: $lineHeight * 2;

  p {
    padding-bottom: $lineHeight;
  }

  .cta--primary {
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }
}

.featured-spotlight__container {
  position: relative;
}

.featured-spotlight__img {
  @include small-only {
    padding-top: 58.4%;
    padding-bottom: 0;
    height: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  img {
    margin: 0 auto;
    display: block;
    width: 100%;

    position: absolute;
    left: 0;
    top: 0;
    margin: 0;

    @include medium {
      width: auto;
      max-width: none;
      left: 100% + $mediumGutterWidth * 2;
    }

    @include wide {
      left: 100% + $wideGutterWidth * 2;
    }
  }
}
