/**
*
* Styleguide
*
**/

.page--styleguide {

  .styleguide__module-title {
    margin-bottom: $lineHeight;
  }

  section {
    margin-bottom: $lineHeight*2;
    border-bottom: 1px solid #ccc;
    padding-bottom: $lineHeight*3 - 1;

  }

  .styleguide__color-list {
    text-align: center;

    li {
      border-bottom: $lineHeight*2 solid;
      margin-bottom: $lineHeight;
      position: relative;
    }
  }

  .styleguide__breadcrumb .breadcrumbs {
    display: block;
  }

  .styleguide__lists {
    ul,
    ol {
      margin-bottom: $lineHeight;
    }
  }

  .styleguide__inverted-block {
    background: #e8e8e8;
    padding: 0 13px;
  }

  .styleguide__theme-block {
    background: $colorLayouts;
    padding: 0 13px;
  }

}

.demo {
  margin-bottom: $lineHeight;
  margin-top: $lineHeight;
}

.demo {
  [class*="g-"] {
    background-color: $colorGrayLight;
    position: relative;
    margin-bottom: $lineHeight;
    min-height: $lineHeight*6;

    &::before,
    &::after {
      @include type--small;
      display: block;
      margin: 0 10px;
    }

    &::before {
      content: 'HTML classes: ';
      font-weight: 700;
    }

    &::after {
      content: attr(class);
      word-spacing: 15px;
    }
  }
}
