/**
*
* Editorial Header
*
**/

.summary-header {
  background-color: $colorBlue;
  padding-bottom: $lineHeight * 3;
  color: #ffffff;
  margin-bottom: $lineHeight;
  box-shadow: inset 0 2px 0 0 #fff;

  .breadcrumbs__link {
    color: #ffffff;
  }

}

.summary-header__anchor-list {
  margin-top: $lineHeight * 2;
}


.summary-header__anchors-item {
  & a {
    color: #ffffff;
  }
}
