/**
*
* Article nav
*
**/

.article-nav {
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    border-left: 2px solid $colorGrayKeyline;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
  }
}

.article-nav-link {
  padding: $lineHeight 32px;
  float: left;
  width: 50%;
  position: relative;

  &::before{
    position: absolute;
    top: 21px;
    font-family: $fontHighlight;
    font-size: $fontMedium;
    font-weight: 400;

    @include medium {
      top: 25px;
      font-size: $fontLarge;
      display: block;
      padding: 13px 10px;
      color: #ffffff;
      background: $colorBlue;
    }
  }
}

.article-nav p {
  padding: 0;
  margin: 0;
}

.article-nav-link--prev {
  text-align: right;
  // border-right-width: 1px;

  &::before {
    font-family: $fontIcon;
    @extend .icon-chevron-left::before;
    left: 32px;
  }

  p {
    @include medium {
      padding-left: 52px;
    }
  }
}

.article-nav-link--next {
  // border-left-width: 1px;

  &::before {
    font-family: $fontIcon;
    @extend .icon-chevron-right::before;
    right: 32px;
  }

  p {
    @include medium {
      padding-right: 52px;
    }
  }
}

.article-nav-count {
  @include type--large;
  font-weight: 700;
  @include medium {font-weight: 400;}
}

