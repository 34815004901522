/**
*
* Text module
*
**/

.did-you-know {

  ol {
    @include medium {
      padding-top: 0 !important;
    }
  }

  .cta--primary {
    margin-top: $lineHeight;
    font-weight: 500;
  }

  &>.g--half {
    position: relative;
    padding-left: 0;
    @include medium {padding-left: 32px}
  }
}

.did-you-know__symbol {
  padding-bottom: $lineHeight*12;
  @include medium {padding-bottom: $lineHeight}

  &::after {
    content: $icon-question;
    color: $colorBlue;
    font-family: $fontIcon;
    font-size: 300px;
    top: 150px;
    left: 30%;
    position: relative;
    display: block;
    width: 0;

    @include medium {
      position: absolute;
      font-size: 400px;
      top: 200px;
      left: 110%;
    }

    @include wide {
      position: absolute;
      font-size: 400px;
      top: 200px;
      left: 124%;
    }
  }
}
