/*
* Web Starter Kit
*
* Multi-screen layout styles for your page. Brought to you by the
* Web Starter Kit team.
*
*/
@import '_h5bp';

/*
* Visual Style Guide styles
* Remove if you prefer to use a CSS library, like Bootstrap
*/
@import 'components/components';

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    position: relative;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-weight: 300;
    background-color: #fff;
    box-sizing: border-box;
    min-height: 100%;
}

body.open {
    overflow: hidden;
}

.app-bar {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fbfbfb;
    overflow: hidden;
    z-index: 1;
}

.app-bar-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 60px;
    position: relative;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0 auto;
}

.app-bar.open,
.open main,
.open .home-spotlight {
    -webkit-transform: translate(250px, 0);
    transform: translate(250px, 0);
}

.app-bar .logo {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 2em;
    line-height: 60px;
    margin: 0 16px;
    padding: 0;
    color: #fefefe;
    float: none;
    max-width: none;
    font-weight: 300;
    display: initial;

    img {
        width: 160px;
    }
}

.box-shadow {
    margin-top: 75px;
    position: relative;
}

.app-bar .logo a {
    text-decoration: none;
    color: inherit;
    font-weight: normal;
}

.app-bar-actions {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    z-index: 2;
}

.app-bar button {
    width: 60px;
    height: 60px;
    background-image: none;
    background-color: transparent;
    border: none;
    padding: 0;
    display: inline-block;
    -webkit-transition: background-color 0.2s ease-out;
    transition: background-color 0.2s ease-out;
    -webkit-tap-highlight-color: transparent;
}

.app-bar button img {
    height: 24px;
    width: 24px;
}

.app-bar button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.app-bar button:focus {
    background-color: rgba(0, 0, 0, 0.2);
    outline: 0;
}

.app-bar button:active {
    background-color: rgba(0, 0, 0, 0.4);
}

.promote-layer {
    /*
    This may promote the layer to a composited
    layer.

    Replace with will-change when available

    #perfmatters
    */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.base,
p,
ul,
ol {
    font-size: 19px;
}

.navdrawer-container {
    z-index: 1;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 250px;
    height: 100%;
    background-color: #3367D6;
    color: #fefefe;
    -webkit-transform: translate(-250px, 0);
    transform: translate(-250px, 0);
    overflow-y: auto;
}

.navdrawer-container.open {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.app-bar,
.navdrawer-container.opened,
main,
.home-spotlight {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
}

.navdrawer-container h4,
.navdrawer-container ul li a {
    height: 60px;
    padding: 17px 20px;
    line-height: 1.4;
}

.navdrawer-container h4 {
    background-color: white;
    color: #3367D6;
}

.navdrawer-container ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.navdrawer-container ul li a {
    display: block;
    text-decoration: none;
    color: white;
    -webkit-transition: background-color 0.2s ease-out;
    transition: background-color 0.2s ease-out;
    white-space: nowrap;
}

.navdrawer-container ul li {
    border-bottom-style: solid;
    border-width: 1px;
    border-color: white;
    padding: 0;
}

.navdrawer-container ul ul {
    position: relative;
    top: 100%;
    z-index: 999;
    display: block;

    li {
        float: none;
        border: none;
    }

}


:root .navdrawer-container ul li {
    float: left \0/IE9;
}

.navdrawer-container ul li::before {
    content: none;
}

.navdrawer-container ul li a {

    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }

    &:focus {
        background-color: rgba(255, 255, 255, 0.3);
        outline: 0;
    }

    &:active {
        background-color: rgba(255, 255, 255, 0.4);
    }
}

.navdrawer-container ul li.active a {
    background-color: #f4b400;

    &:hover {
        background-color: rgba(#fff, 0.2);
    }
}

main {
    margin: 0 auto;
    /* Height of the header */
    padding: 60px 16px 16px 16px;
    min-height: 100%;

    img {
        padding-top: 2.3em;
    }
}

img {
    &.full-width {
        width: 100%;
        height: auto;
    }

    &.align-left {
        float: left;
        padding-right: 1em;
    }

    &.align-right {
        float: right;
        padding-left: 1em;
    }
}

.home-spotlight {
    background-color: #63CC81;
}

.background--grey {
    background-color: #f0f0f0;
}

.slider {
    margin: 28px 0 80px;

    img {
        width: 100%;
    }
}

.slick-arrow {
    position: absolute;
    top: 100%;

    &.slick-prev {
        left: 0;
    }

    &.slick-next {
        right: 0;
    }
}

label {
    margin: 16px 0 8px;
    display: block;
    font-weight: bold;

    em {
        font-style: normal;
        color: $colorRed;
    }
}

input, textarea {
    border: 1px solid #e4e4e4;
    padding: 5px 6px;
    width: 100%;
}

/** Larger Screens - desktops and tablets **/
@media all and (min-width: 990px) {
    .app-bar {
        position: relative;
        background-color: #fff;
    }

    .app-bar.open,
    .app-bar.open ~ main {
        -webkit-transform: translate(0px, 0);
        transform: translate(0px, 0);
    }

    .app-bar-container {
        display: block;
        height: 120px;
        max-width: 864px;
        padding: 0 16px;
        box-sizing: border-box;
        // background-color: #4285f4;
    }

    .app-bar .logo {
        float: left;
        margin: 0;
        padding: 0;
        line-height: 100px;
        font-size: 46px;

        img {
            width: 380px;
        }
    }

    .app-bar-actions {
        float: right;
    }

    .app-bar::after {
        content: ' ';
        display: block;
        height: 0;
        overflow: hidden;
        clear: both;
    }

    button.menu {
        display: none;
    }

    .navdrawer-container {
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 0;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        -webkit-transition: none;
        transition: none;
        overflow-y: visible;
    }

    .navdrawer-container h4 {
        display: none;
    }

    .navdrawer-container ul {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        max-width: 864px;
        margin: 0 auto;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navdrawer-container ul li {
        border: none;
    }

    .navdrawer-container ul ul {
        position: absolute;
        min-width: 200px;
        background-color: $colorBlue;
        display: none;
    }


    main {
        max-width: 864px;
        padding-top: 0;
        min-height: initial;
    }

    body {
        overflow-y: scroll;
    }

    .home-spotlight {
        margin-top: 0;
    }

    .box-shadow {
        margin-top: 15px;
    }
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    src: url(/fonts/RobotoCondensed-Light.eot);
    src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url(/fonts/RobotoCondensed-Light.eot) format('embedded-opentype'), url(/fonts/RobotoCondensed-Light.woff) format('woff');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/RobotoCondensed-Regular.eot);
    src: local('Roboto Condensed Regular'), local('RobotoCondensed-Regular'), url(/fonts/RobotoCondensed-Regular.eot) format('embedded-opentype'), url(/fonts/RobotoCondensed-Regular.woff) format('woff');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: url(/fonts/RobotoCondensed-Bold.eot);
    src: local('Roboto Condensed Regular'), local('RobotoCondensed-Bold'), url(/fonts/RobotoCondensed-Bold.eot) format('embedded-opentype'), url(/fonts/RobotoCondensed-Bold.woff) format('woff');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 300;
    src: url(/fonts/RobotoCondensed-LightItalic.eot);
    src: local('Roboto Condensed Light Italic'), local('RobotoCondensed-LightItalic'), url(/fonts/RobotoCondensed-LightItalic.eot) format('embedded-opentype'), url(/fonts/RobotoCondensed-LightItalic.woff) format('woff');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 400;
    src: url(/fonts/RobotoCondensed-Italic.eot);
    src: local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'), url(/fonts/RobotoCondensed-Italic.eot) format('embedded-opentype'), url(/fonts/RobotoCondensed-Italic.woff) format('woff');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 700;
    src: url(/fonts/RobotoCondensed-BoldItalic.eot);
    src: local('Roboto Condensed Bold Italic'), local('RobotoCondensed-BoldItalic'), url(/fonts/RobotoCondensed-BoldItalic.eot) format('embedded-opentype'), url(/fonts/RobotoCondensed-BoldItalic.woff) format('woff');
}
