/**
*
* Grid
*
**/


@include medium-only {
  // Generating grid classes for medium view
  @for $i from 1 through $mediumColCount {
    .g-medium--#{$i} {
      @include rule--col(medium);
      width: ($mediumColWidth*$i) + ($mediumGutterWidth*($i + -1));
      @if $i == $mediumColCount { margin-right: 0; }
    }

    @if $i < $mediumColCount {
      .g-medium--push-#{$i} {
        margin-left: ($mediumColWidth*$i) + ($mediumGutterWidth*$i);
      }
      .g-medium--pull-#{$i} {
        margin-right: ($mediumColWidth*$i) + ($mediumGutterWidth*$i);
      }
    }
  }

  .g-medium--full {
    @include rule--col(medium);
    margin-right: 0;
    width: 100%;
  }

  .g--third {
    @include rule--col(medium);
    width: $mediumColWidth;
  }

  .g--half,
  .g-medium--half {
    @include rule--col(medium);
    width: 50% - $mediumGutterWidth/2;
  }

  .g-medium--last { margin-right: 0; }
  .g-medium--last + .g-medium--half { clear: left; }

  .g--pull-half { margin-right: 50% + $mediumGutterWidth/2; }
}


@include wide {
  // Generating grid classes for wide view
  @for $i from 1 through $wideColCount {
    .g-wide--#{$i} {
      @include rule--col(wide);
      width: ($wideColWidth*$i) + ($wideGutterWidth*($i + -1));
      @if $i == $wideColCount { margin-right: 0; }
    }

    @if $i < $wideColCount {
      .g-wide--push-#{$i} {
        margin-left: ($wideColWidth*$i) + ($wideGutterWidth*$i);
      }
      .g-wide--pull-#{$i} {
        margin-right: ($wideColWidth*$i) + ($wideGutterWidth*$i);
      }
    }
  }

  .g-wide--last { margin-right: 0; }

  .g-wide--full {
    @include rule--col(wide);
    margin-right: 0;
    width: 100%;
  }

  .g--third {
    @include rule--col(wide);
    width: 30.8%;
  }

  .g--half,
  .g-wide--half {
    @include rule--col(wide);
    width: 50% - $wideGutterWidth/2;
  }

  .g--pull-half { margin-right: 50% + $wideGutterWidth/2; }
}


// This is a global 'last' class
// to be used with global grid classes, such as 'half' or 'third'
// Example usage:
//  <div class="third"></div>
//  <div class="third"></div>
//  <div class="third last"></div>
.g--last { margin-right: 0; }


// This is a global 'centered' class
.g--centered {
  float: none; // reset float to none so we can center it
  margin-left: auto;
  margin-right: auto;
}

// This is a grid overlay
// Its purpose is to show users our grid system
// It becomes visible when a class 'debug' is added to the body
.grid-overlay {

  display: none;
  pointer-events: none;

  // Only show when parents (body) has debug class
  .debug & {
    @include container(true);

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: block;

    [class*="g-"] {
      height: 100%;
      background-color: rgba(lighten(#000, 35%), .2);
    }

    @include medium-only {
      .g-wide--last {
        display: none;
      }
    }

    @include small-only {
      display: none;
    }
  }
}
