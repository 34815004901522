/**
*
* Breadcrumb
*
**/

.breadcrumbs {
  display: none;
  @include medium {
    display: block;
  }

  position: relative;
  z-index: 1;
}

.breadcrumbs p {
  @include type--small;
  padding-top: $lineHeight;
}

.breadcrumbs__link {
  @include type--small;
  color: black;
  font-weight: 400;
  padding-top: 0;

  @include medium {
    padding-top: 0;
  }
}
