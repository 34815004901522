/**
*
* In this guide
*
**/

.in-this-guide {
  margin-top: - $lineHeight * 3;
}

.in-this-guide__title {
  @include type--medium(true);
  font-family: $fontHighlight;
  margin-bottom: $lineHeight;
}
